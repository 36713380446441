import './UserTeamSelector.scss';
import React, { useEffect, useState } from 'react';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import Select from 'react-select';
import { tryParseInt } from '@property-folders/common/util';
import { Avatar } from '@property-folders/components/dragged-components/Avatar';
import { TeamType } from '@property-folders/contract/enums';

export interface MemberOption {
  value: string;
  label: string;
  subtitle?: string;
  auto?: boolean;
}

export type SelectionMode =
  | { type: 'general', entityIds: number[], agentId: number }
  | { type: 'add-to-team', teamId: number, entityId: number, teamType?: TeamType, addAutos?: boolean };

export function UserTeamSelector(props: {
  name: string,
  defaultValue: MemberOption[],
  onChange: (options: MemberOption[]) => void,
  mode: SelectionMode,
  hidden?: boolean
}) {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState<string[]>(props.defaultValue.map(o => o.value));
  const [options, setOptions] = useState<MemberOption[]>(props.defaultValue);

  useEffect(() => {
    if (props.mode.type !== 'general') return;

    setLoading(true);
    AjaxPhp.getEntitiesUserTeams({ entityIds: props.mode.entityIds, agentId: props.mode.agentId })
      .then(data => setOptions(data?.items || []))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [
    props.mode.type,
    props.mode.type === 'general' ? props.mode.entityIds.join(':') : undefined,
    props.mode.type === 'general' ? props.mode.agentId : undefined
  ]);

  useEffect(() => {
    if (props.mode.type !== 'add-to-team') return;

    const addAutos = Boolean(props.mode.addAutos);
    setLoading(true);
    AjaxPhp.getTeamAddableMembers({ teamId: props.mode.teamId, entityId: props.mode.entityId, teamType: props.mode.teamType })
      .then(data => {
        const items = data?.items || [];
        const auto = items
          .filter(item => item.auto);
        setOptions(items);
        if (auto.length && addAutos) {
          setValue(auto.map(item => item.value));
          props.onChange(auto);
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [
    props.mode.type,
    props.mode.type === 'add-to-team' ? props.mode.teamId : undefined,
    props.mode.type === 'add-to-team' ? props.mode.entityId : undefined
  ]);

  return <>
    <input hidden name={props.name} value='' />
    <select
      hidden
      name={`${props.name}[]`}
      value={value}
      multiple
      // @ts-ignore
      readOnly
    >
      {options.map(opt => (<option key={opt.value} value={opt.value}>{opt.label}</option>))}
    </select>
    <Select
      isMulti
      isClearable
      placeholder={'Select users or teams'}
      isLoading={loading}
      options={options}
      value={options.filter(o => value.includes(o.value))}
      className={props.hidden ? 'd-none' : 'user-team-selector'}
      formatOptionLabel={data => {
        const prefix = data.value.substring(0, 2);
        const id = tryParseInt(data.value.substring(2), undefined);
        return <Avatar
          name={data.label}
          entityName={data.subtitle || ''}
          agentId={prefix === 'a_' ? id : undefined}
          teamId={prefix === 't_' ? id : undefined}
        />;
      }}
      onChange={newValue => {
        const newValues = newValue.map(v => v);
        setValue(newValues.map(o => o.value));
        props.onChange(newValues);
      }}
      styles={{
        menu: base => ({
          ...base,
          zIndex: 10
        })
      }}
      classNames={{
        option: () => 'option',
        multiValueLabel: () => 'label'
      }}
    />
  </>;
}
