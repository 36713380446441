import {
  InfiniteScrollListColumn,
  InfiniteScrollRowAction,
  LazyInfiniteTableList
} from '@property-folders/components/dragged-components/LazyInfiniteTableList';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import { useMemo, useState } from 'react';
import { TemplateListItem, TemplatesApi } from '../../../common/client-api/templatesApi';
import { Navigate, useNavigate } from 'react-router-dom';
import { DocumentTypeBadge } from '@property-folders/components/display/DocumentTypeBadge';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '@property-folders/components/display/errors/modals';
import { RenameFormModal } from '@property-folders/components/modals/RenameFormModal';
import { DocumentType, PropertyList } from '@property-folders/contract/rest/document';
import { ArchiveDocumentModal } from '@property-folders/components/dragged-components/ArchiveDocumentModal';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { Button, Container } from 'react-bootstrap';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { AgentAvatar } from '@property-folders/components/display/AgentAvatar';
import {
  HumanTimestampText,
  SupportedRelativeTimeFormatUnit
} from '@property-folders/components/dragged-components/HumanTimestamp';
import { useAgentTimezone } from '@property-folders/components/hooks/useAgentTimezone';
import { SearchBar } from '@property-folders/components/dragged-components/SearchBar';
import { SearchType } from '@property-folders/components/display/SearchType';
import clsJn from '@property-folders/common/util/classNameJoin';
import { useFeatureFlags } from '@property-folders/components/hooks/useFeatureFlags';

export function DocumentTemplateList() {
  const navigate = useNavigate();
  const [showRenameFormModal, setShowRenameFormModal] = useState<boolean>(false);
  const [showArchiveFormModal, setShowArchiveFormModal] = useState<boolean>(false);
  const [workingDocument, setWorkingDocument] = useState<PropertyList | undefined>();
  const timeZone = useAgentTimezone();
  const [showArchived, setShowArchived] = useState(false);
  const { propertyFolderTemplatesList } = useFeatureFlags();
  const [filter, setFilter] = useState('');
  const {
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    data,
    refetch
  } = TemplatesApi.useTemplateList({
    pageNumber: 1,
    searchTerm: filter,
    showArchived
  });

  const items = useMemo<TemplateListItem[]>(() => {
    return (data?.pages.flatMap(page => page.results) ?? []).map(p => ({
      ...p,
      rowClass: clsJn({ 'row-disabled': showArchived })
    }));
  }, [data?.pages, showArchived]);

  const navigateToRow = (row: TemplateListItem) => {
    if (row.type === DocumentType.Subscription) {
      navigate(`/forms.php?DocumentID=${row.subscriptionDocumentId}&ReturnPath=%2Ftemplates`);
    } else if (row.type === DocumentType.Property) {
      navigate(`/templates/${row.propertyId}/document/${row.propertyFormId}`);
    }
  };

  const columns: InfiniteScrollListColumn<TemplateListItem>[] = [
    {
      label: 'Name',
      rowMajor: row => <span className='overflow-hidden text-overflow-ellipsis'>{row.name}</span>,
      rowMinor: row => <div className='d-flex flex-column'>
        {row.formName
          ? <span className='overflow-hidden text-overflow-ellipsis'>{row.formName}</span>
          : <></>}
        <div><DocumentTypeBadge type={row.type} /></div>
      </div>
    },
    {
      label: 'Owner',
      rowMajor: row => <div>
        <AgentAvatar agent={{ agentId: row.ownerId, name: row.ownerName }} fontSize='12px' />
        <span className='ms-2'>{row.ownerName}</span>
      </div>
    },
    {
      label: 'Modified',
      rowMajor: row => (
        <HumanTimestampText
          timestamp={new Date(row.updateStamp) ?? 0}
          maxInterval={SupportedRelativeTimeFormatUnit.week}
          timeZone={timeZone}
        />
      )
    }
  ];
  const rowActions: InfiniteScrollRowAction<TemplateListItem>[] = [
    { label: 'Edit', action: navigateToRow },
    {
      label: 'Rename',
      action: row => {
        setWorkingDocument(row);
        setShowRenameFormModal(true);
      }
    },
    !showArchived
      ? {
        label: 'Archive',
        action: row => {
          setWorkingDocument(row);
          setShowArchiveFormModal(true);
        }
      }
      : {
        label: 'Unarchive',
        action: async row => {
          if (row.type === DocumentType.Subscription) {
            await AjaxPhp.unarchiveDocument(row.subscriptionDocumentId as number);
            await refetch();
          }
        }
      }
  ];

  if (propertyFolderTemplatesList === false) {
    return <Navigate to='/user_form_templates.php' replace={true} />;
  }

  return <Container fluid={true} className='h-100 position-relative d-flex flex-column flex-start g-0'>
    <ContentTitler
      title='Template Management'
      breadcrumbs={[]}
      flex={true}
      scroll={false}
      afterTitle={<>    <Button
        variant='primary'
        size='lg'
        className='d-flex align-items-center'
        onClick={() => navigate('create')}
      >
        <Icon name='add' variant='outlined' icoClass='me-2 fs-4' />
        Create
      </Button>
      <SearchBar onSearch={setFilter} />
      <SearchType
        favourites={false}
        setShowArchived={setShowArchived}
      />
      </>}
    >
      <div className="flex-grow-1 mt-2" style={{ overflow: 'hidden', position: 'relative' }}>
        <LazyInfiniteTableList
          hover={true}
          storageKey="Templates"
          hasNextPage={hasNextPage}
          isFetching={isFetching}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          items={items}
          columns={columns}
          rowHeight="85px"
          rowClick={navigateToRow}
          rowActions={rowActions}
          containerClass=""
        />
      </div>

      <ErrorBoundary fallbackRender={fallback =>
        <FallbackModal
          {...fallback}
          onClose={() => {
            setShowRenameFormModal(false);
            setWorkingDocument(undefined);
          }}
          show={showRenameFormModal}
        />
      }>
        <RenameFormModal
          show={showRenameFormModal}
          setShow={setShowRenameFormModal}
          documentId={workingDocument?.subscriptionDocumentId}
          name={workingDocument?.name}
          refetch={refetch}
        />
      </ErrorBoundary>

      <ErrorBoundary fallbackRender={fallback =>
        <FallbackModal
          {...fallback}
          onClose={() => {
            setShowArchiveFormModal(false);
            setWorkingDocument(undefined);
          }}
          show={showArchiveFormModal}
        />
      }>
        <ArchiveDocumentModal
          show={showArchiveFormModal}
          setShow={setShowArchiveFormModal}
          name={workingDocument?.name}
          onArchive={async () => {
            if (workingDocument?.subscriptionDocumentId) {
              await AjaxPhp.archiveDocument(workingDocument.subscriptionDocumentId);
            }
            await refetch();
            setShowArchiveFormModal(false);
          }}
        />
      </ErrorBoundary>
    </ContentTitler>
  </Container>;
}
